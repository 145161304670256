import * as React from "react"
import { graphql } from "gatsby"
import { Seo } from "../components/seo"
import { Layout } from "../layouts/layout"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"

const ContactPage = ({ data }) => {
  let isEnglish = data.context.context.nodeLocale === "en-CA"
  const path = data.context.context.pathNoLanguagePrefix

  const { contactPerson, email, telephone, businessHours, address } = data.page

  const richTextOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <div className="text-primary">{children}</div>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <div className="text-xl text-gray-900 font-semibold pt-4 pb-3">
          {children}
        </div>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <div className="text-large text-gray-900 font-normal underline pt-4 pb-3">
          {children}
        </div>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <div className="text-sm pb-3">{children}</div>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className="list-disc pl-6">{children}</ul>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <ol className="list-decimal pl-6 pb-0">{children}</ol>
      ),
      [INLINES.HYPERLINK]: ({ data }, children) => {
        return (
          <a
            href={data.uri}
            target="_blank"
            rel="noopener noreferrer"
            className="font-semibold text-pwxBlue hover:underline"
          >
            {children}
          </a>
        )
      },
    },
  }

  return (
    <Layout isEnglish={isEnglish} path={path}>
      <div className="xl:grid grid-cols-2">
        <div className="p-6 xl:p-0 bg-primary flex items-center">
          <div className="py-12 w-full md:w-2/3 m-auto bg-secondary rounded-lg text-primary">
            <div className="lg:grid grid-cols-2 px-6">
              <div className="block uppercase tracking-wide font-bold mb-2 font-mono text-base underline">
                {isEnglish ? "Contact person" : "Contact"}
              </div>
              <div className="">{contactPerson}</div>
            </div>
            <div className="lg:grid grid-cols-2 mt-2 px-6">
              <div className="block uppercase tracking-wide font-bold mb-2 font-mono text-base underline">
                Email
              </div>
              <div className="">{email}</div>
            </div>
            <div className="lg:grid grid-cols-2 mt-2 px-6">
              <div className="block uppercase tracking-wide font-bold mb-2 font-mono text-base underline">
                {isEnglish ? "Telephone" : "Téléphone"}
              </div>
              <div className="">{telephone}</div>
            </div>
            <div className="lg:grid grid-cols-2 mt-2 px-6">
              <div className="block uppercase tracking-wide font-bold mb-2 font-mono text-base underline">
                {isEnglish ? "Address" : "Adresse"}
              </div>
              <div className="">
                {address && renderRichText(address, richTextOptions)}
              </div>
            </div>
            <div className="lg:grid grid-cols-2 mt-2 px-6">
              <div className="block uppercase tracking-wide font-bold mb-2 font-mono text-base underline">
                {isEnglish ? "Business hours" : "Heures d'ouverture"}
              </div>
              <div className="">
                {businessHours &&
                  renderRichText(businessHours, richTextOptions)}
              </div>
            </div>
          </div>
        </div>
        <div className="layout py-12">
          <form
            className="w-full"
            name="contact"
            method="POST"
            data-netlify="true"
            action={isEnglish ? "/en/message" : "/message"}
          >
            <input type="hidden" name="form-name" value="contact" />
            <input
              type="hidden"
              name="subject"
              value="Rigowoodworks.com - Question"
            />

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-secondary font-bold mb-2 font-mono text-base"
                  for="grid-first-name"
                >
                  {isEnglish ? "First Name" : "Prénom"}
                </label>
                <input
                  className="appearance-none block w-full bg-primary text-secondary border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="text"
                  name="First Name"
                  // placeholder="Jane"
                />
                {/* <p className="text-red-500 text-xs italic">
                Please fill out this field.
              </p> */}
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-secondary font-bold mb-2 text-base font-mono"
                  for="grid-last-name"
                >
                  {isEnglish ? "Last Name" : "Nom de famille"}
                </label>
                <input
                  className="appearance-none block w-full bg-primary text-secondary border border-transparent rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                  id="grid-last-name"
                  type="text"
                  name="Last Name"
                  // placeholder="Doe"
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-secondary text-base font-bold mb-2 font-mono"
                  for="grid-password"
                >
                  E-mail
                </label>
                <input
                  className="appearance-none block w-full bg-primary text-secondary border border-transparent rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                  id="email"
                  type="email"
                  name="email"
                />
                {/* <p className="text-gray-600 text-xs italic">
                Some tips - as long as needed
              </p> */}
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-secondary text-base font-bold mb-2 font-mono"
                  for="grid-password"
                >
                  Message
                </label>
                <textarea
                  className=" no-resize appearance-none block w-full bg-primary text-secondary border border-transparent rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-secondary h-48 resize-none"
                  id="message"
                  name="message"
                ></textarea>
                {/* <p className="text-gray-600 text-xs italic">
                Re-size can be disabled by set by resize-none / resize-y /
                resize-x / resize
              </p> */}
              </div>
            </div>
            <div className="md:flex md:items-center">
              <div className="mt-6">
                <button
                  className="inline-block text-center bg-secondary hover:bg-tertiary border border-transparent rounded-md py-3 px-8 font-medium text-white"
                  type="submit"
                >
                  {isEnglish ? "Send" : "Envoyez"}
                </button>
              </div>
              <div className="md:w-2/3"></div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage

export const Head = ({ data }) => <Seo title={data.page.title} />

export const query = graphql`
  query Contact($slug: String!, $nodeLocale: String!) {
    page: contentfulContactPage(
      slug: { eq: $slug }
      node_locale: { eq: $nodeLocale }
    ) {
      title
      slug
      id
      contactPerson
      email
      telephone
      businessHours {
        raw
      }
      address {
        raw
      }
    }
    context: sitePage(
      context: { nodeLocale: { eq: $nodeLocale }, slug: { eq: $slug } }
    ) {
      id
      path
      context {
        slug
        nodeLocale
        pathNoLanguagePrefix
      }
    }
  }
`
